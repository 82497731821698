@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}
.fork-github {
  display: flex;
  justify-content: end;
  position: absolute;
  right: 0;
  width: 130px;
  z-index: 1100;
}
.fork-github img {
  position: relative;
  z-index: 9999;
}
body {
  font-family: 'Poppins', sans-serif;
  color: $main;
  position: relative;
}
a {
  display: inline-block;
  transition: 0.3s ease;
  text-decoration: none !important;
}
span {
  display: inline-block;
}
section#header {
  background: $main;
  color: $second;
}
.container-button {
  display: flex;
  gap: 10px;
}
.flag-button {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  transition: transform 0.4s ease;
  filter: brightness(1); 
}
.flag-button:hover {
  filter: brightness(1.5); 
}
a.navbar-brand {
  font-size: 26px;
  font-weight: 500;
  color: $second;
  span {
    font-weight: 600;
  }
}
a.nav-link {
  font-size: 14px;
  color: $second !important;
  letter-spacing: 0.5px;
  padding: 0px !important;
  margin-left: 40px;
  position: relative;
}
li.nav-item {
  display: flex;
  align-items: center;
  a {
    &:hover {
      &:after {
        width: 95%;
      }
    }
    &:after {
      content: '';
      position: absolute;
      width: 0%;
      height: 2px;
      background: $border;
      left: 50%;
      bottom: -10px;
      transition: 0.3s ease;
      transform: translate(-50%, 0px);
    }
  }
}
a.nav-link.nav-button {
  padding: 10px 15px !important;
  background: $third;
  &:hover {
    background: $border;
  }
  &:after {
    display: none;
  }
}
nav.navbar.navbar-expand-lg {
  padding: 20px 0px;
  background-color: #1b222b;
}
.header-content {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.header-content-wraper {
  h1 {
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 0.5px;
    span {
      font-weight: 600;
    }
  }
  h5 {
    font-size: 20px;
    color: $gray;
    font-weight: 300;
    margin-top: 25px;
  }
  text-align: center;
  margin-top: 160px;
}
.profile-photo {
  img {
    border-radius: 100%;
    width: 170px;
    transition: 0.3s ease;
    cursor: pointer;
    &:hover {
      transform: scale(1.3);
    }
  }
  margin-top: 60px;
}
.arrow-down {
  margin-top: 200px;
  a {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
  }
}
.qui-suis-je-content {
  p {
    text-align: center;
    color: $gray;
    font-size: 14px;
  }
  width: 60%;
  max-width: 650px;
  margin: 0 auto;
  .section-title {
    margin-bottom: 50px;
  }
}
.section-title {
  h2 {
    font-size: 26px;
    font-weight: 600;
    color: $main;
    display: inline-block;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -15px;
      background: $third;
      width: 70%;
      height: 2px;
      transform: translate(-50%, 0px);
    }
  }
  text-align: center;
  min-height: 50px;
}
section#qui-suis-je {
  padding: 90px 0px;
  .col-12 {
    position: relative;
  }
}
.qui-suis-je-image {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0%, -50%);
}
.competences-rating {
  span {
    width: 17px;
    height: 17px;
    border-radius: 100%;
    margin-right: 5px;
  }
}
.fill-color {
  background: $main;
}
.unfill-color {
  background: #ccd6df;
}
.competences-item {
  h3 {
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.competences-wraper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 100px;
  height: 222px;
}
.competences-name {
  h4 {
    font-size: 12px;
    color: $third;
    font-weight: 400;
  }
  width: 35%;
}
.competences-part {
  width: 27%;
}
.competences-item-wraper {
  width: 65%;
}
.competences-item-main {
  display: flex;
  justify-content: space-between;
}
section#competences {
  background: $section;
  padding-bottom: 100px;
}
.competences-main {
  margin-top: 60px;
}
.competences-image {
  text-align: right;
  img {
    margin-right: 100px;
  }
}
.experiances-name {
  p {
    img {
      width: 15px;
    }
    font-size: 13px;
    color: $gray;
    margin-bottom: 5px;
    span {
      font-weight: 500;
    }
  }
  h3 {
    font-size: 18px;
    font-weight: 500;
    color: $main;
    margin-bottom: 15px;
  }
  text-align: right;
  padding-right: 70px;
}
.experiance-office {
  p {
    font-size: 14px;
    color: $gray;
    margin-bottom: 5px;
  }
  h3 {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 20px;
  }
  text-align: left;
  padding-left: 70px;
}
.experiances-item {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: 50px;
  &:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background: $border;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0px);
  }
  &:after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    background: $main;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0px);
    border-radius: 100%;
  }
  > div {
    width: 50%;
  }
  &:last-child {
    padding-bottom: 0px;
  }
}
section#experiances {
  padding: 100px 0px;
  padding-bottom: 0;
  .container {
    position: relative;
    .row {
      padding-bottom: 100px;
    }
  }
}
.experiances-main {
  margin-top: 70px;
}
.tree-image {
  position: absolute;
  bottom: 0;
  left: 0;
}
section#formations {
  background: $section;
  padding: 100px 0px;
  .section-title {
    margin-bottom: 30px;
  }
  .container {
    position: relative;
  }
}
.formations-item {
  h3 {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
    span {
      font-weight: 500;
    }
  }
  p {
    font-size: 13px;
    color: $gray;
    margin-bottom: 5px;
  }
  text-align: center;
  margin-top: 45px;
  &:last-child {
    margin-bottom: 0px;
  }
}
.graduation-image {
  position: absolute;
  top: -10px;
  right: 0;
}
section#certifications {
  background: $main;
  padding: 100px 0px;
  color: $second;
  .section-title {
    h2 {
      color: $second;
    }
  }
}
.certifications-list {
  p {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 10px;
    span {
      color: $third;
    }
    span::before {
      content: '// ';
    }
  }
}
.certifications-wraper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 80px;
  > div {
    width: 48%;
  }
}
.certifications-left {
  img {
    width: 100%;
    width: 400px;
  }
  text-align: right;
}
.input-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    border: 2px solid $border;
    width: 30%;
    padding: 10px 30px;
    border-radius: 20px;
    font-size: 14px;
    &:focus {
      border: 2px solid $third;
    }
  }
}
.contact-wraper {
  form {
    textarea {
      border: 2px solid $border;
      padding: 10px 30px;
      border-radius: 20px;
      width: 100%;
      margin-top: 30px;
      font-size: 14px;
      &:focus {
        border: 2px solid $third;
      }
    }
  }
  margin-top: 70px;
  width: 85%;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 70px;
}
:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
::placeholder {
  color: $main;
}
.form-btn {
  text-align: center;
  margin-top: 30px;
  button {
    background: $third;
    padding: 10px 100px;
    border: none;
    border-radius: 20px;
    color: $second;
    transition: 0.3s ease;
    &:hover {
      background: $border;
    }
  }
}
section#contact {
  padding: 100px 0px;
  padding-bottom: 0px;
  position: relative;
  .container {
    position: relative;
  }
}
.contact-info {
  img {
    width: 30px;
    margin-right: 30px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 65px;
  a {
    font-size: 13px;
    color: $gray;
    margin-bottom: 5px;
    &:hover {
      color: $third;
    }
  }
}
.mailbox-image {
  position: absolute;
  left: 0;
  bottom: 0px;
}
section#footer {
  background: $main;
  padding: 25px 0px;
}
.footer-social {
  img {
    width: 25px;
  }
  a {
    margin-left: 15px;
    &:hover {
      transform: scale(1.3);
    }
  }
}
.copyright {
  p {
    color: $second;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0px;
    a {
      color: $third;
    }
  }
}
.footer-wraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.back-to-top {
  position: absolute;
  right: 30px;
  bottom: 20px;
  animation: mover 1s infinite alternate;
}
html {
  scroll-behavior: smooth;
}
div#mobile-menu {
  display: none;
}
a.link-twitter {
  img {
    margin-top: 6px;
  }
}
.navbar-brand,
.navbar-brand:hover {
  color: $second;
}
